.contacto-container {
    background-color: var(--background-color);
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
}

ul {
    list-style-type: none; /* Quita los puntos de la lista */
    padding: 0; /* Opcional: elimina el padding izquierdo del ul */
    margin-bottom: 25px; /* Opcional: elimina el margen del ul */
}


/* Contenedor del formulario */
.formulario-container {
    width: 20vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 50px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;   
}

/* Contenedor de mapa y lista */
.mapa-lista-container {
    width: 20vw;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: auto;
    padding: 50px;
    
}

/* Título de la lista */
.dispensadores-title {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

/* Elementos de la lista */
.dispensador-item {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    color: var(--primary-color);
    font-size: 1rem;;
}

.dispensador-item:hover {
    background-color: var(--accent-color);
    color: #fff;
}

.dispensador-item.activo {
    background-color: var(--primary-color);
    color: #fff;
}



/* Estilos del formulario */
.formulario-title {
    font-size: 1.8rem;
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 1.5rem;
}

.formulario {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin: 16px 0px;
}

.form-label {
    font-size: 1rem;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
}

.form-input,
.form-textarea {
    padding: 0.75rem;
    font-size: 1rem;
    border: 2px solid var(--accent-color);
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.form-input:focus,
.form-textarea:focus {
    border-color: var(--primary-color);
    outline: none;
}

.form-button {
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: var(--button-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-button:hover {
    background-color: var(--button-hover-color);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-button {
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: var(--button-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-button:hover {
    background-color: var(--button-hover-color);
}


/* Responsividad */
@media (max-width: 768px) {
    .contacto-container {
        width: 80vw;
        height: auto;
        flex-direction: column-reverse;
    }

    .mapa-container {
        width: 95%;
        height: 40vh; /* Reduce el tamaño del mapa */
        margin: 50px;
    }

    .dispensadores-lista {
        width: 80%;
        height: auto;
        margin-bottom: 50px;
    }

    .formulario-container {
        width: 80%;
        height: 50vh;
        padding-inline: 50px;
        background-color: #fff;
        border-radius: 25px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }

}

@media (max-width: 470px) {
    .contacto-container {
        width: 70vw;
        height: auto;
        flex-direction: column-reverse;
    }

    .mapa-container {
        width: 95%;
        height: 40vh; /* Reduce el tamaño del mapa */
        margin: 50px;
    }

    .dispensadores-lista {
        width: 80%;
        height: auto;
        margin-bottom: 50px;
    }

    .formulario-container {
        width: 80%;
        height: 50vh;
        padding-inline: 50px;
        background-color: #fff;
        border-radius: 25px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
}
