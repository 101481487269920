/* Reset de margen y padding */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
}

/* Estilo de la cabecera */
.cabecera {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: #000;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}

/* Estilo del logo y el título */
.cabecera-logo {
    display: flex;
    align-items: center;
}

.logo {
    height: 40px;
    width: 40px;
    margin-right: 0.5rem;
}

.titulo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    text-decoration: none;
}

/* Navegación en pantallas grandes */
.navegacion {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-right: 50px;
}

.enlace {
    font-size: 1rem;
    color: #000;
    text-decoration: none;
    transition: color 0.3s ease;
}

.enlace:hover {
    color: #96d8d9;
}

/* Menú hamburguesa para dispositivos móviles */
.menu-button {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #000;
}

/* Responsividad para pantallas pequeñas */
@media (max-width: 768px) {
    /* Ocultar la navegación principal y mostrar el botón de menú */
    .navegacion {
        display: none; /* Ocultar navegación */
        position: absolute;
        top: 60px; /* Posicionarlo debajo de la cabecera */
        left: 0;
        width: 100%;
        flex-direction: column;
        background-color: #ffffff;
        padding: 1rem 0;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        z-index: 5;
    }
    /* Mostrar el botón de menú de hamburguesa en pantallas pequeñas */
    .menu-button {
        display: flex;
        font-size: 1.5rem;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        color: #000;
        padding-right: 50px;
    }

    /* Mostrar el menú desplegable cuando está abierto */
    .navegacion.open {
        display: flex; /* Mostrar navegación en modo abierto */
    }

}

/* Botón de salida */
.boton-salir {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none;
    transition: color 0.3s ease;
}

.boton-salir:hover {
    color: #96d8d9;
}

/* Estilos para el crédito del usuario */
.credito-usuario {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #000;
    margin-right: 1rem;
}

.icono-credito {
    margin-right: 0.3rem;
    color: #FFD700; /* Color dorado para el ícono */
}
