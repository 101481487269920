.en-construccion-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    text-align: center;
    padding: 2rem;
    box-sizing: border-box;
}

.construccion-image {
    width: auto;
    max-width: 80%;
    margin-bottom: 1.5rem;
}

.construccion-title {
    font-size: 2rem;
    color: #0080c2;
    margin-bottom: 1rem;
}

.construccion-text {
    font-size: 1rem;
    color: #666;
    max-width: 600px;
    line-height: 1.5;
}