/* Colores base */
:root {
    --primary-color: #0080c2;
    --accent-color: #96d8d9;
    --background-color: #cfeefd;
    --button-color: #0080c2;
    --button-hover-color: #96d8d9;
}

body, html {
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-title {
    font-size: 1.8rem;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-label {
    font-size: 0.9rem;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
}

.form-input {
    padding: 0.75rem;
    font-size: 1rem;
    border: 2px solid var(--accent-color);
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.form-input:focus {
    border-color: var(--primary-color);
    outline: none;
}

.form-button {
    margin-top: auto;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: var(--button-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-button:hover {
    background-color: var(--button-hover-color);
}
