.tip-detail-container {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f9f9f9;
    position: absolute;
    top: 80px;
    left: 0;
    padding-bottom: 30px;
}

.tip-detail-content {
    width: 80vw;
    height: auto;
    
    color: #333;
}

.header-section {
    text-align: center;
    margin-bottom: 40px;
}

.header-section h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 1.2em;
    color: #777;
    margin-bottom: 20px;
}

.banner-image {
    width: 30%;
    height: auto;
    border-radius: 10px;
}

.content-section h2 {
    font-size: 1.8em;
    margin-top: 30px;
    color: #444;
}

.content-section p {
    font-size: 1.1em;
    line-height: 1.8;
    margin-bottom: 20px;
}

.content-section ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
}

.content-section li {
    margin-bottom: 10px;
}

.tips-extra { 
    background-color: #f9f9f9; 
    padding: 20px; 
    border-left: 4px solid #ff6f61; 
    margin: 30px 0; 
} 

.tips-extra h3 { 
    text-align: center; /* Centra el título */ 
    margin-top: 0; 
    font-size: 1.5em; /* Ajusta el tamaño del título según sea necesario */ 
    color: #444; /* Opcional: color del título */ 
} 

.tips-extra ul { 
    text-align: left; /* Alinea el texto a la izquierda */ 
    list-style-type: disc; 
    margin-left: 20px; 
    margin-bottom: 20px; 
} 

.tips-extra li { 
    margin-bottom: 10px; 
    font-size: 1em; /* Ajusta el tamaño del texto de la lista según sea necesario */ 
    color: #333; /* Opcional: color del texto de la lista */ 
}

.call-to-action {
    text-align: center;
    margin-top: 40px;
}

.call-to-action p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.share-button {
    background-color: #ff6f61;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
}

.share-button:hover {
    background-color: #ff4b3a;
}


.image-section { 
    display: flex; 
    align-items: center; 
    background-color: #ffffff;
    border-radius: 8px; 
    box-shadow: 0 15px 10px rgba(0,0,0,0.3);
    padding: 20px; 
    max-width: 800px; 
    margin: 20px auto; 
} 

.section-image { 
    width: 150px; 
    height: auto; 
    border-radius: 8px; 
    margin-right: 20px; 
} 

.image-text { 
    max-width: 600px;
} 
 
.image-title { 
    font-size: 1.2em; 
    margin-bottom: 10px; 
    color: #4CAF50; 
} 

.image-description { 
    font-size: 1em; 
    line-height: 1.6; 
    color: #666; 
}



/* Responsividad */
@media (max-width: 768px) {

    .banner-image {
        width: 50%;
        height: auto;
        border-radius: 10px;
    }
    
}

@media (max-width: 480px) {
    
    .tip-detail-content {
        width: 90vw;
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: center;
        align-items: center;
    }

    .tips-extra { 
        width: 70%;
        background-color: #f9f9f9; 
        padding: 20px; 
        border-left: 4px solid #ff6f61; 
        margin: 30px 0; 
    } 

    .banner-image {
        width: 80%;
        height: auto;
        border-radius: 10px;
    }

    .content-section {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #444;
    }

    .image-section { 
        width: 70vw;
        display: flex; 
        flex-direction: column;
        align-items: center; 
        background-color: #ffffff;
        border-radius: 20px; 
        box-shadow: 0 15px 10px rgba(0,0,0,0.3);
        padding: 20px; 
        max-width: 800px; 
        margin: 40px auto; 
    } 

    .section-image { 
        width: 60%; 
        height: auto; 
        border-radius: 8px; 
        margin-right: 20px; 
    }

    .image-title { 
        font-size: 2em; 
        margin-bottom: 10px; 
        color: #4CAF50; 
    }

    .image-description { 
        width: 100%;
        font-size: 1rem; 
        line-height: 1.6; 
        color: #666; 
    }

    .description { 
        width: 100%;
        font-size: 1rem; 
        line-height: 1.6; 
        color: #666; 
    }

}
