/* Tarjeta general */
.card {
    width: 20vw;
    height: auto; /* Ajusta la altura según el contenido */
    border-radius: 30px;
    background: #f5f5f5;
    box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 25px;
    text-align: center;
    position: relative; /* Necesario para el título */
    overflow: hidden; /* Evita que elementos salgan del contenedor */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 15px 15px 35px #bebebe, -15px -15px 35px #ffffff;
}

/* Título */
.card-title {
    position: absolute; /* Posiciona el título encima de la imagen */
    top: 130px; /* Coloca el título en la parte superior */
    left: 0;
    width: auto;
    background: rgba(0, 0, 0, 0); /* Fondo semitransparente */
    color: #fff; /* Color de texto blanco */
    font-size: 2rem;
    color: #000000;
    font-weight: bold;
    text-align: center;
    z-index: 2; /* Asegura que esté encima de la imagen */
    border-radius: 30px 30px 0 0; /* Bordes superiores redondeados */
}

/* Imagen */
.card-image {
    width: 100%;
    height: 200px;
    transform: scale(1); /* Escala la imagen al 90% de su tamaño */
    object-fit: cover;
    object-position: top;
    border-radius: 30px 30px 0 0; /* Bordes superiores redondeados */
    opacity: 0.7;
}

/* Tipo de producto */
.card-type {
    font-size: 10pt;
    color: #000000;
    background-color: #00000000;
    margin: 10px 0px;
    padding: 5px;
    border-radius: 12px;
    text-transform: uppercase;
}

/* Descripción */
.card-description {
    font-size: 12pt;
    color: #666;
    padding: 0px 28px;
    text-align: justify;
}

/* Botón */
.card-button {
    padding: 7px 15px;
    margin-bottom: 17px;
    font-size: 12pt;
    color: #ffffff;
    background-color: #0080c2;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.card-button:hover {
    background-color: #005f8a;
}

/* Responsividad */
@media (max-width: 768px) {
    .card {
        width: 40vw;
    }

    /* Título */
    .card-title {
        font-size: 25pt;
    }

}

@media (max-width: 500px) {
    
    .card {
        width: 70vw;
    }
    /* Título */
    .card-title {
        font-size: 25pt;
    }


}
