.inicio-container{
    width: 100vw;
    height: 100vh;
    margin-top: var(--header-height);
}

.inicio-background {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    border: #333 solid 20px;
}

.inicio-overlay {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Ajusta la opacidad */
    z-index: 1;
}

.inicio-content {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Carrusel flotante */
.floating-carousel {
    padding: 2rem;
    border-radius: 17px;
    max-width: 60%; /* Ajuste al 50% del ancho */
    width: 100%;
    text-align: center;
}

.carousel-card {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 300px; /* Limita el ancho de las tarjetas */
    margin: auto; /* Centra las tarjetas */   
}

.carousel-image {
    width: 100%; /* Ajusta el ancho al 100% del contenedor */
    max-width: 250px; /* Limita el ancho máximo */
    max-height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* Estilos de las tarjetas del carrusel */
.carousel-card h2 {
    font-size: 1.8rem;
    color: #0080c2;
    margin-bottom: 1rem;
}

.carousel-card p {
    font-size: 1.1rem;
    color: #333;
    line-height: 1.5;
}

/* Cambia la imagen de fondo en pantallas pequeñas */
@media (max-width: 768px) {
    .inicio-background {
        background-image: url('../../img/FondoMovil.png');
    }

    .carousel-card h2 {
        font-size: 1.5rem;
    }

    .carousel-card p {
        font-size: 1rem;
    }
}






/* Sección de productos */
.products-section {
    height: auto;
    width: 100vw;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-top: 2rem; /* Espaciado entre la sección anterior */
}

.products-section h2 {
    font-size: 2rem;
    color: #0080c2;
    margin-bottom: 6rem;
    text-align: center;
}

/* Tarjetas de productos */
.products-list {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.product-card {
    background-color: #ffffff;
    width: 250px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    text-align: center;
}

.product-image {
    width: 100%;
    height: 250px;
    object-fit: contain;
    border-radius: 17px;
}

.product-card h3 {
    font-size: 1.2rem;
    color: #333;
    margin-top: 1rem;
}

.product-card p {
    font-size: 1rem;
    color: #666;
    margin: 0.5rem 0 1rem;
}

.view-more-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    color: #ffffff;
    background-color: #0080c2;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.view-more-button:hover {
    background-color: #005f8a;
}



/* Estilos para la sección de Consejos, Tips y Más */
.tips-section {
    height: 100vh;
    width: 100vw;
    padding: 4rem 2rem;
    background-color: #e8f6ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-top: 2rem;
}

.tips-section h2 {
    font-size: 2rem;
    color: #0080c2;
    margin-bottom: 2rem;
    text-align: center;
}

.carousel-card {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 300px; /* Limita el ancho de las tarjetas */
    margin: auto; /* Centra las tarjetas */
}


.carousel-card img {
    width: 100%;
    max-width: 200px;
    margin-bottom: 1rem;
    border-radius: 8px;
}

.carousel-card h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
}

.carousel-card p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
}

.carousel-card .view-more-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #0080c2;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.carousel-card .view-more-button:hover {
    background-color: #005f8a;
}

/* Responsividad para móviles */
@media (max-width: 768px) {
    .tips-section {
        padding: 2rem 1rem; /* Reduce el padding para móviles */
        overflow-y: scroll; /* Habilita el scroll vertical */
    }

    .floating-carousel {
        max-width: 80%; /* Ajuste al 50% del ancho */
        
    }

    .carousel-card {
        max-width: 90%; /* Ajusta el ancho de las tarjetas para que ocupen casi toda la pantalla */
        padding: 1rem; /* Reduce el padding para pantallas más pequeñas */
        height: 70%;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Suaviza la sombra en pantallas pequeñas */
        border-radius: 20px; /* Ajusta ligeramente el radio de las esquinas */
    }

    .carousel-card h2 {
        font-size: 1.2rem; /* Reduce el tamaño del título */
    }

    .carousel-card p {
        font-size: 0.9rem; /* Ajusta el tamaño del texto */
        line-height: 1.4; /* Mejora la legibilidad en pantallas pequeñas */
    }

    .carousel-card img {
        max-width: 150px; /* Reduce el tamaño de las imágenes */
    }

    .carousel-card .view-more-button {
        font-size: 0.9rem; /* Ajusta el tamaño del botón */
        padding: 0.4rem 0.8rem;
    }
}