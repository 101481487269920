.dispensadores-container {
    width: 100vw; /* Ajusta el ancho del contenedor */
    height: 100vh; /* Permite que la altura sea dinámica */
    display: flex;
    flex-direction: row;
    align-items: center; /* Centra horizontalmente los elementos */
    justify-content: space-around; /* Asegura que los elementos comiencen desde arriba */
    overflow: hidden; /* Evita que el contenido desborde */
    box-sizing: border-box; /* Incluye borde y padding en el tamaño total */
}

.dispensadores-lista {
    width: 50%; /* Asegúrate de que ocupe todo el ancho permitido */
    max-width: 100%; /* Limita el ancho al tamaño del padre */
    margin: 5rem;
    height: auto; /* Altura ajustable según el contenido */
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    padding: 1rem; /* Espacio interno para evitar colisiones con el contenido */
    box-sizing: border-box;
    overflow-y: auto; /* Habilita scroll vertical si es necesario */
}

/* Estilo del mapa */
.mapa-container {
    width: 50%;
    height: 400px;
    border-radius: 25px;
    margin-right: 5rem;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 0;
}


.final {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: absolute;
    top: 0;
    left: 0;
}

.dispensadores-title {
    font-size: 1.7rem;
    color: #0080c2;
    text-align: center;
}


.dispensador-item {
    border-radius: 4px;
    transition: background-color 0.3s ease;
    background-color: #fff;
    color: #333;
    font-size: 1rem;
}

.dispensador-item:hover {
    background-color: #e0f0ff;
    color: #0080c2;
}

.dispensador-item.activo {
    background-color: #0080c2;
    color: #fff;
}

.ContenedorG {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.graficas-container {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}  

.grafica {
    width: 90vw;
    height: 60vh;
}

.points-container{
    width: 100vw;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.btn {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #40B3A2;
    min-width: 200px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 16px 20px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
  }
  
  .btn:hover {
    opacity: .95;
  }
  
  .btn .animation {
    border-radius: 100%;
    animation: ripple 0.6s linear infinite;
  }
  
  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
    }
  
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
    }
  }

  .TituloGraficas {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem; /* Ajusta el tamaño del texto según tus necesidades */
    }  

    .subrayado{
        text-decoration: underline; /* Subraya el texto */
    }
    
    .TituloText{
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: justify;
        text-decoration: none;
    }

/* Responsividad */
@media (max-width: 470px) {

    .dispensadores-container {
        width: 80%; /* Ajusta el ancho del contenedor */
        height: auto; /* Permite que la altura sea dinámica */
        display: flex;
        flex-direction: column;
        align-items: center; /* Centra horizontalmente los elementos */
        justify-content: start; /* Asegura que los elementos comiencen desde arriba */
        overflow: hidden; /* Evita que el contenido desborde */
        box-sizing: border-box; /* Incluye borde y padding en el tamaño total */
    }
    
    .dispensadores-lista {
        width: 100%; /* Asegúrate de que ocupe todo el ancho permitido */
        max-width: 100%; /* Limita el ancho al tamaño del padre */
        height: auto; /* Altura ajustable según el contenido */
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 25px;
        padding: 1rem; /* Espacio interno para evitar colisiones con el contenido */
        box-sizing: border-box;
        overflow-y: auto; /* Habilita scroll vertical si es necesario */
    }

    .dispensadores-title {
        font-size: 1.5rem;
        color: #0080c2;
        text-align: center;
    }

    .dispensadores-lista ul {
        list-style-type: none; /* Quita los puntos de lista */
        margin: 0;
        padding: 0;
        width: 70%; /* Asegura que la lista ocupe todo el ancho */
        margin-bottom: 20px;
    }

    .dispensador-item {
        border-radius: 7px;
        transition: background-color 0.3s ease;
        background-color: #fff;
        color: #333;
        font-size: 1rem;
        margin: 10px 0px;
    }
    

    .mapa-container {
        width: 100%;
        height: 400px;
        border-radius: 25px;
        overflow: hidden;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        z-index: 0;
    }

    .ContenedorG {
        
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .final {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        position: absolute;
        top: 20px;
        left: 0;
    }

    .graficas-container {
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 100px;
    }      
    .grafica {
        width: 100%;
        max-width: 500px;
        height: 300px;
    }
    
    grafica h4 {
        text-align: center;
        color: #7ba0b2;
    }

    .TituloGraficas {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1rem; /* Ajusta el tamaño del texto según tus necesidades */
        }  
    
        .subrayado{
            text-decoration: underline; /* Subraya el texto */
        }
        
        .TituloText{
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: justify;
            text-decoration: none;
        }  
}
