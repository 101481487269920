.auth-container {
    width: 80vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #cfeefd;
}

.auth-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.auth-tabs button {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #0080c2;
    color: #fff;
    transition: background-color 0.3s ease;
}

.auth-tabs button.active {
    background-color: #96d8d9;
}

.auth-form {
    width: 100%;
    max-width: 400px;
}
