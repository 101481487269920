/* Estilos para el footer */
.footer {
    width: 100%;
    background-color: var(--primary-color);
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;  /* Ocultar por defecto */
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.footer-content {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.contact-section {
    flex: 1;
    display: flex;
    justify-content: center;
}

.contact-button {
    background-color: var(--button-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
}

.contact-button:hover {
    background-color: var(--button-hover-color);
}

.social-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-link {
    color: #fff;
    text-decoration: none;
    font-size: 1em;
    display: flex;
    align-items: center;
}

.social-icon {
    font-size: 1.5em;
    margin-right: 10px;
}

.social-link:hover {
    color: var(--accent-color);
}
