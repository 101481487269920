.tips-container {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    position: absolute;
    top: 100px;
    left: 0;
    padding-bottom: 70px;
}

.contenedor{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    background-color: #f9f9f9;
    padding-bottom: 20px;
}

.tips-title {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
}

.search-bar {
    width: 80%;
    padding: 0.5rem;
    font-size: 1rem;
    margin-bottom: 2rem;
    border: 2px solid var(--accent-color);
    border-radius: 8px;
}

.tips-grid {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
}

.tip-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 300px;
    text-align: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.tip-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.tip-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
}

.tip-title {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin: 1rem 0;
}

.tip-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
}

.tip-button {
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.tip-button:hover {
    background-color: var(--button-hover-color);
}

/* Responsividad para pantallas pequeñas */
@media (max-width: 760px) {
    .contenedor{
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 25px;
        background-color: #f9f9f9;
        padding-bottom: 20px;
    }

    .tips-grid {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        justify-content: center;
    }
    
    .tip-card {
        background: #fff;
        border-radius: 12px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        width: 90%;
        text-align: center;
        transition: transform 0.2s ease, box-shadow 0.2s ease;
    }

}


/* Responsividad para pantallas pequeñas */
@media (max-width: 470px) {
    .contenedor{
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 25px;
        background-color: #f9f9f9;
        padding-bottom: 20px;
    }

    .tips-grid {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        justify-content: center;
    }
    
    .tip-card {
        background: #fff;
        border-radius: 12px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        width: 300px;
        text-align: center;
        transition: transform 0.2s ease, box-shadow 0.2s ease;
    }

}