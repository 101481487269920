.modal-overlay {
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.modal-title {
    margin-top: 40px;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #0080c2;
}

.modal-body {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.modal-close {
    margin-bottom: 40px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #0080c2;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-close:hover {
    background-color: #96d8d9;
}
