/* Fondo de la página */
.beneficios-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #cfeefd; /* Fondo azul claro */
}

/* Estilo de la tarjeta flotante */
.auth-card {
    background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco con transparencia */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra para el efecto flotante */
    text-align: center;
    max-width: 400px;
    width: 100%;
    backdrop-filter: blur(10px); /* Efecto de desenfoque para una sensación de flotación */
}

.auth-card h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
}

.auth-card p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
}

.auth-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #0080c2;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.auth-button:hover {
    background-color: #006a9e;
}
