/* Contenedor del grid */
.productos-grid {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    position: absolute;
    top: 80px;
    left: 0;
}

/* General Modal Styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 400px;
    width: 90%;
}

.modal-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #0080c2;
}

.modal-content p {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #333;
}

.modal-button {
    padding: 0.5rem 1rem;
    background-color: #0080c2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.modal-button:hover {
    background-color: #005f8a;
}


/* Responsividad */
@media (max-width: 768px) {
    .productos-grid {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        flex-wrap: wrap;
        position: absolute;
        top: 80px;
        left: 0;
    }
}

@media (max-width: 480px) {
    .productos-grid {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        position: absolute;
        top: 80px;
        left: 0;
    }
}
